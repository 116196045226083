import { defineMessages } from 'react-intl';

export const scope = 'barcelona.templates.BlogTag';

export default defineMessages({
  pageTitle: {
    id: `${scope}.pageTitle`,
    defaultMessage: 'Tag: {tag}{pageNumber, plural, =0 {} other {, page {pageNumber}}}'
  },
  seoTitle: {
    id: `${scope}.seoTitle`,
    defaultMessage: 'Tag: {tag} - from the blog{pageNumber, plural, =0 {} other {, page {pageNumber}}}'
  },
  seoMetaDescription: {
    id: `${scope}.seoMetaDescription`,
    defaultMessage: 'We are proud to present our travel blog tag: {tag}{pageNumber, plural, =0 {} other {, page {pageNumber}}}, packed with travel guides and useful information for your next holiday'
  }
});
